<template>
    <!-- <div class="row">
        <div class="col-12">
            <router-link class="btn btn-light-primary text-primary fs-3 mb-3" to="/branch/category/">
                <i class="ti ti-chevron-left"></i>
            </router-link>
        </div>
    </div> -->
    <form ref="form" @submit.prevent="submitForm">
        <div class="row mb-3 text-center">
            <div class="col-12">
                <h4>Status</h4>
                <select v-model="reservationForm.status" class="form-select" name="status" id="status">
                    <option value="New">New</option>
                    <option value="Pending">Pending</option>
                    <option value="Paid">Paid</option>
                    <option value="Canceled">Canceled</option>
                    <option value="Finished">Finished</option>
                </select>
                <!-- <h5 class="fw-bold">
                    <div class="col-6" v-for="(language, index) in languages" :key="index">
                        <div class="input-group mb-3">
                            <span class="input-group-text" :id="`categoryName${capitalize(language.iso)}`">{{ capitalize(language.iso) }}</span>
                            <input v-model="categoryForm.name[language.iso]" type="text" class="form-control" :id="`categoryName${capitalize(language.iso)}`" :aria-describedby="`categoryName${capitalize(language.iso)}`">
                        </div>
                    </div>
                </h5> -->
            </div>
        </div>
        <div class="row mb-3 text-center">
            <div class="col-6">
                <h4>Code</h4>
                <h5 class="fw-bold">
                    {{ reservationForm.code }}
                </h5>
            </div>
            <div class="col-6">
                <h4>Product</h4>
                <h5 class="fw-bold">
                    {{ reservationForm.product.name.en }} - {{ reservationForm.product.name.es }}
                </h5>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-3">
                <h4>Name</h4>
                <h5>{{ reservationForm.contact.title }} {{ reservationForm.contact.firstName }} {{ reservationForm.contact.lastName }}</h5>
            </div>
            <div class="col-3">
                <h4>Phone</h4>
                <h5>{{ reservationForm.contact.phone }}</h5>
            </div>
            <div class="col-3">
                <h4>Email</h4>
                <h5>{{ reservationForm.contact.email }}</h5>
            </div>
            <div class="col-3">
                <h4>Birthday</h4>
                <h5>{{ reservationForm.contact.birthday.toDate().toDateString() }}</h5>
            </div>
        </div>
        <div class="row mb-5">
            <div class="col-3">
                <h4>Adult</h4>
                <h5>{{ reservationForm.pax.adult.qty }}</h5>
            </div>
            <div class="col-3">
                <h4>Old</h4>
                <h5>{{ reservationForm.pax.old.qty }}</h5>
            </div>
            <div class="col-3">
                <h4>Child</h4>
                <h5>{{ reservationForm.pax.child.qty }}</h5>
            </div>
            <div class="col-3">
                <h4>Infant</h4>
                <h5>{{ reservationForm.pax.infant.qty }}</h5>
            </div>
        </div>
        <div class="row mb-3 text-end">
            <div class="col-4">
                <h4>Total</h4>
                <h5>${{ reservationForm.detail.total }}</h5>
            </div>
            <div class="col-4">
                <h4>Discount</h4>
                <h5>${{ reservationForm.detail.discount }}</h5>
            </div>
            <div class="col-4">
                <h4>Subtotal</h4>
                <h5>${{ reservationForm.detail.subtotal }}</h5>
            </div>
        </div>
        
        <!-- <div class="mb-3">
            <label for="categoryOrder">Order</label>
            <select v-model="categoryForm.order" class="form-select" name="categoryOrder" id="categoryOrder">
                <option value="null" disabled>Select option</option>
                <option v-for="i in 20" :key="i" :value="i">{{ i }}</option>
            </select>
        </div> -->
        <!-- <div class="row" v-if="languages">
            <div class="col-6" v-for="(language, index) in languages" :key="index">
                <div class="input-group mb-3">
                    <span class="input-group-text" :id="`categoryName${capitalize(language.iso)}`">{{ capitalize(language.iso) }}</span>
                    <input v-model="categoryForm.name[language.iso]" type="text" class="form-control" :id="`categoryName${capitalize(language.iso)}`" :aria-describedby="`categoryName${capitalize(language.iso)}`">
                </div>
            </div>
        </div> -->
        <!-- <div class="mb-3">
            <label for="categoryOrder">Order</label>
            <select v-model="categoryForm.order" class="form-select" name="categoryOrder" id="categoryOrder">
                <option value="null" disabled>Select option</option>
                <option v-for="i in 20" :key="i" :value="i">{{ i }}</option>
            </select>
        </div>
        <div class="mb-3 form-check">
            <input v-model="categoryForm.isActive" type="checkbox" class="form-check-input" id="categoryIsActive">
            <label class="form-check-label" for="categoryIsActive">Is Active</label>
        </div>
        <div class="mb-3 form-check">
            <input v-model="categoryForm.isVisible" type="checkbox" class="form-check-input" id="categoryIsVisible">
            <label class="form-check-label" for="categoryIsVisible">Is Visible</label>
        </div> -->
        <div class="row">
            <div class="col-12 text-end">
                <button type="submit" class="btn btn-success">Save</button>
            </div>
        </div>
    </form>
</template>

<script>

import { ConfigMethods } from '/src/config.js'
import { onMounted, computed } from "vue"
import { useStore } from "vuex"
import { useRouter } from "vue-router"
import { useNotification } from "@kyvg/vue3-notification"
import { db } from '../../firebaseConfig'
import { doc, updateDoc } from 'firebase/firestore'
import { useLoading } from 'vue-loading-overlay'

export default {
    setup() {
        var loader = null
        const store = useStore()
        const router = useRouter()
        const { notify }  = useNotification()

        const $loading = useLoading({
            color: '#539BFF'
        })

        const reservationForm = computed(() => {
            return store.getters.reservation.data
        })

        const submitForm = async () => {
            loader = $loading.show()
            addToCollection()
            .then(() => {
                loader.hide()
            })
        }

        async function addToCollection() {
            updateDoc(doc(db, `${ConfigMethods.MAIN_URL}/Reservation/`, reservationForm.value.id), {
                status: reservationForm.value.status
            }).then(() => {
                notify({
                    type: "success",
                    title: "It was updated correctly"
                })
            })
        }

        onMounted(async () => {
            if( store.getters.reservation.data == null ) {
                router.push('/reservation/')
            }
        })

        return { reservationForm, submitForm  }
    },
    components: {
        
    }
}
</script>