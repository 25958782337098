<template>
    <form ref="form" @submit.prevent="submitForm" novalidate>
        <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item" role="presentation">
                <a class="nav-link active" id="simple-tab-0" data-bs-toggle="tab" href="#simple-tabpanel-0" role="tab" aria-controls="simple-tabpanel-0" aria-selected="false">Promotions</a>
            </li>
            <li class="nav-item" role="presentation">
                <a class="nav-link" id="simple-tab-1" data-bs-toggle="tab" href="#simple-tabpanel-1" role="tab" aria-controls="simple-tabpanel-1" aria-selected="true">Configuration</a>
            </li>
            <li class="nav-item" role="presentation">
                <a class="nav-link" id="simple-tab-2" data-bs-toggle="tab" href="#simple-tabpanel-2" role="tab" aria-controls="simple-tabpanel-2" aria-selected="false">About Us</a>
            </li>
            <li class="nav-item" role="presentation">
                <a class="nav-link" id="simple-tab-3" data-bs-toggle="tab" href="#simple-tabpanel-3" role="tab" aria-controls="simple-tabpanel-3" aria-selected="false">Contact Us</a>
            </li>
            <li class="nav-item" role="presentation">
                <a class="nav-link" id="simple-tab-4" data-bs-toggle="tab" href="#simple-tabpanel-4" role="tab" aria-controls="simple-tabpanel-4" aria-selected="false">Privacy Policy</a>
            </li>
            <li class="nav-item" role="presentation">
                <a class="nav-link" id="simple-tab-5" data-bs-toggle="tab" href="#simple-tabpanel-5" role="tab" aria-controls="simple-tabpanel-5" aria-selected="false">Terms & Conditions</a>
            </li>
            <li class="nav-item" role="presentation">
                <a class="nav-link" id="simple-tab-6" data-bs-toggle="tab" href="#simple-tabpanel-6" role="tab" aria-controls="simple-tabpanel-6" aria-selected="false">Social Networks</a>
            </li>
        </ul>

        <div class="tab-content pt-5" id="tab-content">
            <div class="tab-pane active" id="simple-tabpanel-0" role="tabpanel" aria-labelledby="simple-tab-0">
                <div class="mb-3">
                    <label for="siteBranch">Branch</label>
                    <select class="form-select" name="siteBranch" id="siteBranch" v-model="siteForm.branch" @change="updateCategory">
                        <option value="null" disabled>Select option</option>
                        <option v-for="(branch) in branches" :key="branch.id" :value="branch.id">
                            {{ branch.name.en }} - {{ branch.name.es }}
                        </option>
                    </select>
                </div>
                <div class="mb-3">
                    <label for="siteDiscountPromotion">Discount Percentage</label>
                    <input v-model="siteForm.discount" id="siteDiscountPromotion" class="form-control" type="number" />
                </div>
                <div class="mb-3">
                    <label for="siteItemPromotion">Item with promotion</label>
                    <Multiselect
                        v-model="siteForm.promotion"
                        :options="items"
                        ref="promotionRef"
                    />
                </div>
                <div class="mb-3">
                    <label for="siteOurBest">Our best items</label>
                    <Multiselect
                        v-model="siteForm.bestItems"
                        mode="tags"
                        :max=4
                        :options="items"
                        ref="bestItems"
                    />
                </div>
            </div>
            <div class="tab-pane" id="simple-tabpanel-1" role="tabpanel" aria-labelledby="simple-tab-1">
                <div class="mb-3">
                    <label for="siteTitle">Title</label>
                    <input v-model="siteForm.title" id="siteTitle" class="form-control" type="text" />
                </div>
                <div>
                    <div id="imageHelp" class="form-text">Favicon. Recommended size: 32x32px. All types of image formats are accepted.</div>
                </div>
                <div class="input-group mb-3">
                    <input @change="handleFileChange" type="file" accept="image/*" class="form-control" id="inputGroupFile" aria-describedby="inputGroupFile" aria-label="Upload">
                </div>
                <div class="mb-3">
                    <!-- <label for="siteFavicon">Favicon</label> -->
                    <div class="upload__img-wrap">
                        <div class="upload__img-box" v-for="(image, index) in images" :key="index">
                            <div :style="`background-image: url('${image.url}')`" class="img-bg">
                                <div @click="deleteImage(image, index)" class="upload__img-close"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane" id="simple-tabpanel-2" role="tabpanel" aria-labelledby="simple-tab-2">
                <div class="row" v-if="languages">
                    <div class="col-6" v-for="(language, index) in languages" :key="index">
                        <div class="input-group mb-3">
                            <label class="text-uppercase" :for="`siteAboutUs${capitalize(language.iso)}`">{{ language.iso }}&nbsp;&nbsp;&nbsp;&nbsp;</label>
                            <editor v-model="siteForm.aboutus[language.iso]" :id="`siteAboutUs${capitalize(language.iso)}`"
                                api-key="6uv2ah3d5hqqtr5raox98pd8pxz8bgk2guh5kvuxulqpu9bh" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane" id="simple-tabpanel-3" role="tabpanel" aria-labelledby="simple-tab-3">
                <div class="row" v-if="languages">
                    <div class="col-6" v-for="(language, index) in languages" :key="index">
                        <div class="input-group mb-3">
                            <label class="text-uppercase" :for="`siteContactUs${capitalize(language.iso)}`">{{ language.iso }}&nbsp;&nbsp;&nbsp;&nbsp;</label>
                            <editor v-model="siteForm.contactus[language.iso]" :id="`siteContactUs${capitalize(language.iso)}`"
                                api-key="6uv2ah3d5hqqtr5raox98pd8pxz8bgk2guh5kvuxulqpu9bh" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane" id="simple-tabpanel-4" role="tabpanel" aria-labelledby="simple-tab-4">
                <div class="row" v-if="languages">
                    <div class="col-6" v-for="(language, index) in languages" :key="index">
                        <div class="input-group mb-3">
                            <label class="text-uppercase" :for="`sitePrivacy${capitalize(language.iso)}`">{{ language.iso }}&nbsp;&nbsp;&nbsp;&nbsp;</label>
                            <editor v-model="siteForm.privacy[language.iso]" :id="`sitePrivacy${capitalize(language.iso)}`"
                                api-key="6uv2ah3d5hqqtr5raox98pd8pxz8bgk2guh5kvuxulqpu9bh" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane" id="simple-tabpanel-5" role="tabpanel" aria-labelledby="simple-tab-5">
                <div class="row" v-if="languages">
                    <div class="col-6" v-for="(language, index) in languages" :key="index">
                        <div class="input-group mb-3">
                            <label class="text-uppercase" :for="`siteTerms${capitalize(language.iso)}`">{{ language.iso }}&nbsp;&nbsp;&nbsp;&nbsp;</label>
                            <editor v-model="siteForm.terms[language.iso]" :id="`siteTerms${capitalize(language.iso)}`"
                                api-key="6uv2ah3d5hqqtr5raox98pd8pxz8bgk2guh5kvuxulqpu9bh" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane" id="simple-tabpanel-6" role="tabpanel" aria-labelledby="simple-tab-6">
                <div class="mb-3">
                    <label for="companyFacebook">Facebook</label>
                    <input v-model="siteForm.socialNetworks.facebook" type="text" class="form-control" id="companyFacebook">
                </div>
                <div class="mb-3">
                    <label for="companyInstagram">Instagram</label>
                    <input v-model="siteForm.socialNetworks.instagram" type="text" class="form-control" id="companyInstagram">
                </div>
                <div class="mb-3">
                    <label for="companyX">X</label>
                    <input v-model="siteForm.socialNetworks.x" type="text" class="form-control" id="companyX">
                </div>
                <div class="mb-3">
                    <label for="companyWhatsapp">Whatsapp</label>
                    <input v-model="siteForm.socialNetworks.whatsapp" type="text" class="form-control" id="companyWhatsapp">
                </div>
                <div class="mb-3">
                    <label for="companyLinkedIn">LinkedIn</label>
                    <input v-model="siteForm.socialNetworks.linkedin" type="text" class="form-control" id="companyLinkedIn">
                </div>
                <div class="mb-3">
                    <label for="companyTikTok">Tik Tok</label>
                    <input v-model="siteForm.socialNetworks.tiktok" type="text" class="form-control" id="companyTikTok">
                </div>
            </div>
        </div>

       
        <div class="row">
            <div class="col-12 text-end">
                <button type="submit" class="btn btn-success">Save</button>
            </div>
        </div>
    </form>
</template>

<style src="@vueform/multiselect/themes/default.css"></style>
<script>
import { ConfigMethods } from '/src/config.js'
import { computed, watch, onMounted, ref, reactive } from "vue"
import { useStore } from "vuex"
import { db, storage } from '../../firebaseConfig'
import { doc, setDoc, getDoc, query, updateDoc, collection, orderBy, onSnapshot } from "firebase/firestore"
import { ref as storageRef, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage'
import { useNotification } from "@kyvg/vue3-notification"
import { useLoading } from 'vue-loading-overlay'
import Multiselect from '@vueform/multiselect'
import Editor from '@tinymce/tinymce-vue'

export default {
    setup() {
        var loader = null
        const store = useStore()
        const branches = ref([])
        const items = ref([])
        const { notify } = useNotification()
        const multipleImages = ref(false)
        const images = ref([])
        const deletedImages = ref([])
        const files = ref([])
        const urls = ref([])
        var isEdit = false
        var loaded = false

        const $loading = useLoading({
            color: '#539BFF'
        })

        const languages = computed(() => {
            return (store.getters.general.data) ? store.getters.general.data.language : []
        })

        function capitalize(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }

        const formInitialState = {
            branch: '',
            discount: 0,
            promotion: '',
            bestItems: [],
            aboutus: {},
            contactus: {},
            privacy: {},
            terms: {},
            socialNetworks: {}
        }

        const siteForm = reactive({ ...formInitialState })

        
        const submitForm = async () => {
            loader = $loading.show()
            uploadImagesInStorage()
            .then(() => {
                addToCollection()
                .then(() => {
                    deleteImagesInStorage()
                    .then(() => {
                        deletedImages.value = []
                        files.value = []
                        urls.value = []
                        document.getElementById('inputGroupFile').value= null
                        loader.hide()
                    })
                })
            })
        }

        async function addToCollection() {
            if(urls.value.length == 0 && deletedImages.value.length == 0) {
                if(images.value.length > 0) {
                    siteForm.icon = images.value[0].url
                } else {
                    siteForm.icon = ""    
                }
            } else if(urls.value.length == 0 && deletedImages.value.length > 0) {
                siteForm.icon = ""
            } else {
                siteForm.icon = urls.value[0]
                let object = {
                    name: urls.value[0],
                    url: urls.value[0]
                }
                images.value[0] = object
            }

            let promotion = siteForm.promotion
            let bestItems = siteForm.bestItems

            let itemPromotionDocRef = ""
            if(siteForm.branch) {
                itemPromotionDocRef = doc(db, `${ConfigMethods.MAIN_URL}/Branch/${siteForm.branch}/Menu/${siteForm.promotion}`)
            }

            let arrayItemRef = []
            siteForm.bestItems.forEach((item) => {
                let itemRef = doc(db, `${ConfigMethods.MAIN_URL}/Branch/${siteForm.branch}/Menu/${item}`)
                arrayItemRef.push(itemRef)
            })

            siteForm.promotion = itemPromotionDocRef
            siteForm.bestItems = arrayItemRef
            
            if(isEdit) {
                await updateDoc(doc(db, `${ConfigMethods.MAIN_URL}/General/`, "Site"), siteForm).then(() => {
                    siteForm.promotion = promotion
                    siteForm.bestItems = bestItems

                    notify({
                        type: "success",
                        title: "It was updated correctly"
                    })
                })
            } else {
                delete siteForm.branch
                setDoc(doc(db, `${ConfigMethods.MAIN_URL}/General`, "Site"), siteForm)
                .then(() => {
                    notify({
                        type: "success",
                        title: "It was saved correctly"
                    })
                })
            }
        }

        const deleteImage = (file, index) => {
            if (isURL(file.url)) {
                deletedImages.value.push(file)
            } else {
                for (var i = 0; i < files.value.length; i++) {
                    if (files.value[i].name === file.name) {
                        files.value.splice(i, 1);
                        break
                    }
                }
            }

            images.value.splice(index, 1);
        }

        const handleFileChange = (e) => {
            if (e.target.files) {
                if( !multipleImages.value ) {
                    if(images.value.length > 0) {
                        deleteImage(images.value[0], 0)
                    }
                    images.value = []
                    files.value = []
                }

                var reader  = new FileReader()
                for (const file of e.target.files) {
                    files.value.push(file)
                    reader.onloadend = function () {
                        let object = {
                            name: file.name,
                            url: reader.result
                        }
                        images.value.push(object)
                    }
                    reader.readAsDataURL(file)
                }
            }
        }

        function getRandomFileName() {
            var timestamp = new Date().toISOString().replace(/[-:.]/g,"")
            var random = ("" + Math.random()).substring(2, 8);
            var random_number = timestamp+random
            return random_number
        }

        async function deleteImagesInStorage() {
            const promises = []
            const deleteFile = async (storageReference) => {
                await deleteObject(storageReference)
            }
            
            try {
                deletedImages.value.forEach((file) => {
                    const storageReference = storageRef(storage, file.url)
                    promises.push(deleteFile(storageReference))
                })

                await Promise.all(promises)
            } catch (error) {
                console.log(error);
            }
        }

        async function uploadImagesInStorage() {
            const promises = []
            const uploadFile = async (storageReference, file) => {
                await uploadBytes(storageReference, file)
                await getDownloadURL(storageReference)
                .then((url) => {
                    urls.value.push(url)
                })
            }

            try {
                files.value.forEach((file) => {
                    let name = getRandomFileName()
                    const storageReference = storageRef(storage, 'icon/' + name)
                    promises.push(uploadFile(storageReference, file))
                })
            } catch (error) {
                console.log(error);
            }
            
            await Promise.all(promises)
        }

        function initImages() {
            if(siteForm.icon != "" && siteForm.icon != null && siteForm.icon != undefined) {
                let object = {
                    name: siteForm.icon,
                    url: siteForm.icon
                }
                images.value.push(object)
            }
        }

        function isURL(string) {
            let url
            try {
                url = new URL(string)
            } catch (_) {
                return false;  
            }

            return url.protocol === "http:" || url.protocol === "https:";
        }

        const updateCategory = async (e) => {
            let idBranch = e.target.value
            getCategory(idBranch)
        }

        function getCategory(idBranch) {
            siteForm.promotion = ""
            siteForm.bestItems = []

            const q = query(collection(db, `${ConfigMethods.MAIN_URL}/Branch/${idBranch}/Menu`), orderBy('order'))
            onSnapshot(q, (querySnapshot) => {
                items.value = []
                querySnapshot.forEach((doc) => {
                    items.value.push({ value: doc.id, label: doc.data().name.en })
                })
            })
        }

        async function loadData() {
            const docRef = doc(db, `${ConfigMethods.MAIN_URL}/General`, 'Site')
            const docSnap = await getDoc(docRef)
            if (docSnap.exists()) {
                isEdit = true
                let data = docSnap.data()

                Object.assign(siteForm, data)

                var branch = (data.promotion) ? data.promotion.path.split("/") : ""
                let arrayBestItems = []

                data.bestItems.forEach((doc) => {
                    arrayBestItems.push(doc.id)
                })

                siteForm.branch = (branch[3]) ? branch[3] : ""

                if(siteForm.branch) {
                    getCategory(siteForm.branch)
                }

                siteForm.promotion = (branch[5]) ? branch[5] : ""
                siteForm.bestItems = arrayBestItems
                
                initImages()
            }
        }

        function loadBranch() {
            const q = query(collection(db, `${ConfigMethods.MAIN_URL}/Branch`), orderBy('order'));
            onSnapshot(q, (querySnapshot) => {
                branches.value = []
                querySnapshot.forEach((doc) => {
                    branches.value.push({ id: doc.id, ...doc.data() })
                })
                
                if(!loaded) {
                    loadData()
                    loaded = true
                }

            })
        }

        watch(() => siteForm,
            async () => {
                initImages()
            }
        )

        onMounted(() => {
            loadBranch()
        })

        return { languages, siteForm, branches, items, images, capitalize, submitForm, handleFileChange, deleteImage, updateCategory  }
    },
    components: {
        Multiselect,
        'editor': Editor
    }
}
</script>